@import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@300;700&display=swap');


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}



html {
    scroll-behavior: smooth;
}

* {
    margin: 0;
    padding: 0;
    font-family: 'Readex Pro', sans-serif;
}

.goog-te-banner-frame.skiptranslate {
    display: none !important;
} 

body {
    top: 0px !important; 
    }



nav {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

a.nav-link {
    color: #0000dc !important;
    margin-right: 1.5rem;
    font-size: 12px;
}

a#register-btn {
    color: #fff !important;
    background-color: #ffc400;
    border-radius: 50px;
    padding: 8px 30px;
}

a#register-btn:hover {
    color: #ffc400 !important;
    background-color: #fff;
    border: 1px solid #ffc400;
}


/*FIRST CAROUSEL*/
h2#bootcamp {
    font-size: 60px;
    font-weight: bold;
    color: #000;
}

a#reg-btn {
    font-size: 30px;
    background-color: #0000dc;
    color: #fff;
    padding: 8px 70px;
    text-decoration: none;
    border-radius: 50px;
    transition: transform .2s ease; 
}

a#reg-btn:hover {
    background-color: #fff;
    color: #0000bd;
    border: 1px solid #0000bd;
    transform: scale(10.3);
}

#carousel-caption1 {
    width: auto;
    display: flex;
    align-items: center;
    bottom: 50%;
    top: 50%;
    text-align: left;
}

p#learner-text {
    width: 160px;
    display: flex;
    position: absolute;
    top: 24%;
    left: 51%;
    color: #fff;
    font-size: 20px;
}

/*FISRT CAROUSEL*/

/*SECOND CAROUSEL*/
h2#bootcamp2 {
    font-size: 50px;
    font-weight: bold;
    color: #000;
}

a#reg-btn2 {
    font-size: 30px;
    background-color: #0000dc;
    color: #fff;
    padding: 8px 70px;
    text-decoration: none;
    border-radius: 30px;
    transition: transform .2s ease ;
}

a#reg-btn2:hover {
    background-color: #fff;
    color: #0000bd;
    transform: scale(10.3);
}

#carousel-caption2 {
    width: auto;
    display: flex;
    align-items: center;
    bottom: 50%;
    top: 50%;
    text-align: left;
}
/*SECOND CAROUSEL*/


/*THIRD CAROUSEL*/
h2#bootcamp2 {
    font-size: 50px;
    font-weight: bold;
    color: #000;
}

a#reg-btn3 {
    font-size: 30px;
    background-color: #0000dc;
    color: #fff;
    padding: 8px 70px;
    text-decoration: none;
    border-radius: 30px;
    transition: transform .2s ease ;
}

a#reg-btn3:hover {
    background-color: #fff;
    color: #0000bd;
    border: 1px solid #0000bd;
    transform: scale(10.3);
}

#carousel-caption3 {
    width: auto;
    display: flex;
    align-items: center;
    bottom: 50%;
    top: 50%;
    text-align: left;
}
/*THIRD CAROUSEL*/




h4#theProgram {
    font-size: 32px;
}

p#theText {
    color: #707070;
}

p#theText2 {
    color: #707070;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    justify-content: center;
}

p#details {
    color: #707070;
    font-weight: bold;
}

a#register-btn2 {
    font-size: 18px;
    color: #fff;
    background-color: #0000dc;
    padding: 8px 50px;
    border-radius: 50px;
    text-decoration: none;
    transition: transform .2s ease;
}

a#register-btn2:hover {
    background-color: #fff;
    color: #0000bd;
    border: 1px solid #0000bd;
    transform: scale(10.3);
}

div#theModule {
    position: relative;
    bottom: 25rem;
}

div#regHere {
    position: relative;
    top: -15rem;
    margin-bottom: -10rem;
}

a#submit-form {
    font-size: 18px;
    color: #fff;
    background-color: #0000dc;
    padding: 8px 50px;
    border-radius: 50px;
    text-decoration: none;
}

button#subsBtn:hover {
    transform: scale(1.04);
}

#theCard {
    background-color: #0000dc;
    border-radius: 20px;
}

a#paynow-btn {
    font-size: 18px;
    padding: 10px 0px;
    color: #0000dc;
    border-radius: 30px;
    background-color: #ffc400;
    text-decoration: none;
}

a#paynow-btn:hover {
    color: #0000dc;
    background-color: #fff;
}

a#social-media {
    text-decoration: none;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 50px;
    padding: 7px 10px;
    margin-right: 1rem;
}

a#proceedBtn {
    font-size: 18px;
    color: #fff;
    background-color: #010483;
    padding: 10px 30px;
}


#whyCard {
    height: 330px;
    background-color: #fff;
    border-radius: 15px;
    padding: 8px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}



/*Showcase Start*/

.showcase-box {
    margin: 5px 20px 10px 20px;
    overflow: hidden;
}

.showcase-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
}

.cs-hidden {
    overflow: hidden !important;
}
/*Showcase End*/



/*==================Sign In and Sign Up Modal Start======================*/

#theModalTitle {
    font-weight: bold;
    font-size: 22px;
    border-bottom: 2px solid #0000dc;
    padding-bottom: 5px;
}

#signin-btn {
    background-color: #0000dc;
    border-radius: 30px;
    padding: 5px 40px;
    color: #fff;
}

/*==================Sign In and Sign Up Modal End======================*/


#signOut-btn {
    background-color: #0000dc;
    border-radius: 30px;
    padding: 5px 40px;
    color: #fff;
    text-decoration: none;
}





@media (max-width: 720px) {

    img#theLogo {
        width: 100px;
    }

    a#social-media {
        margin-left: 13px !important;
    }

    #carouselExampleDark {
        margin-top: 2.5rem;
    }
    
    a#register-btn {
        color: #fff !important;
        background-color: #ffc400;
        border-radius: 50px;
        padding: 8px 30px !important;
        margin-right: 22rem !important;
        padding-left: 35px !important;
    }

    /*FIRST CAROUSEL*/
    h2#bootcamp {
        font-size: 20px;
        font-weight: bold;
        color: #000;
        margin-bottom: 0rem !important;
    }

    a#reg-btn {
        font-size: 10px;
        background-color: #0000dc;
        color: #fff;
        padding: 4px 25px;
        text-decoration: none;
        border-radius: 30px;
        position: relative;
        top: 1rem !important;
    }

    a#reg-btn:hover {
        padding-left: 28px;
        padding-right: 28px;
        background-color: #0000bd;
    }

    #carousel-caption1 {
        text-align: left;
        margin-bottom: 0rem !important;
        
    }

    p#learner-text {
        width: 60px;
        top: 22%;
        left: 50%;
        font-size: 7px;
    }
    /*FISRT CAROUSEL*/


    /*SECOND CAROUSEL*/
    h2#bootcamp2 {
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 0rem !important;
    }

    a#reg-btn2 {
        font-size: 8px;
        background-color: #0000dc;
        color: #fff;
        padding: 4px 25px;
        text-decoration: none;
        border-radius: 30px;
        position: relative;
        top: .5rem;
    }

    a#reg-btn2:hover {
        padding-left: 28px;
        padding-right: 28px;
        background-color: #0000bd;
    }

    p#slider2Text {
        font-size: 8px;
        margin-bottom: 0rem !important;
        position: relative;
        top: .5rem;
    }


    #carousel-caption2 {
        text-align: left;
        margin-bottom: -2rem !important;
    }
    /*SECOND CAROUSEL*/


    /*THIRD CAROUSEL*/
    h2#bootcamp2 {
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 0rem !important;
    }

    a#reg-btn3 {
        font-size: 8px;
        background-color: #0000dc;
        color: #fff;
        padding: 4px 25px;
        text-decoration: none;
        border-radius: 30px;
        position: relative;
        top: .5rem;
    }

    a#reg-btn3:hover {
        padding-left: 28px;
        padding-right: 28px;
        border: 1px solid #0000dc;
        background-color: #0000bc;
    }

    p#slider2Text {
        font-size: 8px;
        margin-bottom: 0rem !important;
        position: relative;
        top: .5rem;
    }


    #carousel-caption2 {
        text-align: left;
        margin-bottom: -2rem !important;
    }
    /*THIRD CAROUSEL*/


    div#program {
        margin-top: -4rem;
    }

    p#theTopText {
        position: absolute;
        bottom: 32.5rem;
        left: 2rem;
        font-size: 14px;
    }

    div#sponsors {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    div.showcase-box > img {
        width: 30px;
    }

 
    p#details {
        font-size: 12px;
    }


    div#modulesContainer {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    h4.modTitle {
        margin-top: -3rem;
    }
    
    img#theRobotWhite {
        width: 35px;
        margin-top: -2rem;
    }

    p#modText {
        margin-top: -2rem;
    }

    img#theRobotWhite2 {
        width: 35px;
        margin-top: -4rem;
    }

    p#modText2 {
        margin-top: -2.5rem;
    }

    div#regContainer {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    a#submit-form {
        font-size: 12px;
        margin-left: 12rem !important;
    }

    div#paySection {
        margin-top: 3rem !important;
    }

    div#costContainer {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    #subsMail {
        padding-left: 1.3rem !important;
        margin-top: 1rem;
    }

    #subsName {
        padding: 0px 1.3rem;
    }

}



@media screen and (max-width: 768px) {

    img#theLogo {
        width: 100px;
    }

    img#theBG-Image {
        width: 100%;
    }

    a#register-btn {
        color: #fff !important;
        background-color: #ffc400;
        border-radius: 50px;
        padding: 8px 30px !important;
        width: 130px;
        margin-right: 22rem !important;
        padding-left: 37px !important;
    }

    div#program {
        margin-top: -4rem;
    }

    a#main-btn {
        font-size: 10px;
        background-color: #fff;
        color: #0000dc;
        padding: 4px 25px;
        border-radius: 50px;
        text-decoration: none;
    }


    div#modulesContainer {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    h4.modTitle {
        margin-top: -3rem;
    }
    
    img#theRobotWhite {
        width: 35px;
        margin-top: -2rem;
    }

    p#modText {
        margin-top: -2rem;
    }

    img#theRobotWhite2 {
        width: 35px;
        margin-top: -4rem;
    }

    p#modText2 {
        margin-top: -2.5rem;
    }

    div#regContainer {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    a#submit-form {
        font-size: 10px;
        margin-left: 7rem !important;
    }

    div#paySection {
        margin-top: 3rem !important;
    }

    div#costContainer {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    a#social-media {
        margin: 0rem 0rem 2rem;
    }

    img#roundRobot {
        width: 200px !important;
    }

}