@import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@300;700&display=swap');

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

* {
    font-family: 'Readex Pro', sans-serif;
}

body.swal2-shown > [aria-hidden="true"] {
    transition: 0.1s filter;
    filter: blur(10px);
}


#regContainer {
    margin-top: 20px;
}

.multistep-container {
    max-width: 900px;
    margin: auto;
}

.multistep-container .active-button {
    display: table;
    margin: auto;
    padding-left: 0;
    overflow: hidden;
    margin-bottom: 20px;
}

.multistep-container .active-button li {
    display: inline-block;
    width: 100px;
}

.multistep-container .active-button li .round-btn {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #c8d0df;
    display: block;
    color: #fff;
    text-align: center;
    font-weight: 500;
    line-height: 30px;
    max-width: 100%;
    margin-left: 0;
    position: relative;
    z-index: 1;
}

.multistep-container .active-button li.active .round-btn {
    background-color: #0000dc;
    color: #fff;
}

.multistep-container .active-button li:not(:first-child) .round-btn:before {
    position: absolute;
    content: '';
    width: 71px;
    height: 3px;
    background-color: #c8d0df;
    left: -70px;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
}


.multistep-container .active-button li.active:not(:first-child) .round-btn:before {
    background-color: #0000dc;
}

.multistep-container .multistep-form-area {
    background-color: #fff;
    margin-top: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 20px;
    position: relative;
    min-height: 585px;
    max-height: 585px;
}

.multistep-container .multistep-form-area .form-box {
    position: absolute;
    top: 35px;
    right: 80px;
}

/*.multistep-container .multistep-form-area #theForm:nth-child(2),
.multistep-container .multistep-form-area #theForm:nth-child(3)
{
    display: none;
}
*/

.multistep-container .multistep-form-area .form-box .button-row .next,
.multistep-container .multistep-form-area .form-box .button-row .previous,
.multistep-container .multistep-form-area .form-box .button-row .submit
{
    background-color: #0000dc;
    border: 0;
    font-size: 18px;
    margin-right: 15px;
    box-shadow: none;
    min-width: 100px;
    border-radius: 20px;
    color: #fff;
    font-family: 'roboto';
    font-weight: 500;
}

.multistep-container .multistep-form-area .form-box .button-row {
    display: table;
    margin: auto;
    margin-top: 20px;
}

.multistep-container .multistep-form-area .form-box .button-row .submit {
    background-color: #ffc400;
    padding: 7px 30px;
}

.multistep-container .multistep-form-area .form-box .button-row .previous {
    background-color: #fff;
    color: #000;
}

.multistep-container .multistep-form-area .form-box .button-row .next {
    padding: 7px 40px;
    transition: transform .2s ease;
    padding: 7px 30px;
}

.multistep-container .multistep-form-area .form-box .button-row .next:hover {
    background-color: #fff;
    color: #0000bd;
    border: 1px solid #0000bd;
    transform: scale(1.04);
}

#confirm-btn {
    background-color: #0000dc;
    color: #fff;
    padding: 5px 25px;
    border-radius: 30px;
}


#modal-header {
    border-bottom: 0 none;
}

#modal-footer {
    border-top: 0 none;
}

#online-btn {
    color: #000;
    text-decoration: none;
    font-size: 22px;
}

#online-btn-active {
    color: #000;
    text-decoration: none;
    font-size: 22px;
    border-bottom: 2px solid #0000dc;
    padding-bottom: 5px;
}

#bank-btn {
    color: #000;
    text-decoration: none;
    font-size: 22px;
    margin-left: 2rem;
}

#bank-btn-active {
    color: #000;
    text-decoration: none;
    font-size: 22px;
    border-bottom: 2px solid #0000dc;
    padding-bottom: 5px;
    margin-left: 2rem;
}

p#light-text {
    color: #707070;
    font-size: 12px;
}

p#bold-text {
    color: #000;
    font-size: 18px;
    font-weight: bold;
}

#amountUni {
    font-weight: bold;
    font-size: 40px;
}

#amountBasic {
    font-weight: bold;
    font-size: 40px;
}


#modalContent {
    background-color: #fff;
    border-radius: 20px;
}

#theHeading {
    color: #15c2a5
}

#hr {
    border:none;
    border-top:2.5px dotted #000; 
    color:#fff;
    background-color:#fff;
    width:100%;
}

#icon {
    background-color: #15c2a5;
    color: #fff;
    padding: 8px;
    border-radius: 50%;
    font-size: 35px;
}

#receiptCard {
    border-radius: 20px;
    background-color: #fff;
    margin-left: 27rem;
    margin-right: 27rem;
    margin-top: 3rem;
    padding: 10px 30px;
}

@media print {
    body * {
      visibility: hidden;
      -webkit-print-color-adjust: exact !important;
      
      page-break-after: avoid;
      page-break-before: avoid;
    }
    #receiptCard, #receiptCard * {
      visibility: visible;
    }
    #receiptCard {
      left: 10rem;
      position: absolute;
      right: 10rem;
      align-self: center;
      width: 50%;
      overflow: hidden;
      margin-top: 8rem;
      padding: 0 !important;
    }

    #save-receipt {
        display: none;
    }

}



@media (max-width: 720px) {

    .multistep-container .active-button li:not(:first-child) .round-btn:before {
        display: none;
    }
    
    .multistep-container .active-button li.active:not(:first-child) .round-btn:before {
        display: none;
    }

    .multistep-container .active-button li .round-btn {
        display: none;
    }
    
    .multistep-container .active-button li.active .round-btn {
        display: none;
    }

    #formImg {
        display: none;
    }

    .multistep-container .multistep-form-area .form-box {
        position: absolute;
        top: 10px;
        right: 10px;
        left: 10px;
    }

    #regText1 {
        font-size: 18px;
        position: absolute;
        text-align: center;
    }


    .multistep-container .multistep-form-area .form-box .button-row .next {
        padding: 5px 30px;
    }

    


    .multistep-container .multistep-form-area .form-box .button-row .next,
    .multistep-container .multistep-form-area .form-box .button-row .previous,
    .multistep-container .multistep-form-area .form-box .button-row .submit
    {
        font-size: 18px;
        margin-right: 12px;
        padding: 7px 20px;
        min-width: 100px;
        border-radius: 20px;
    }

    #receiptCard {
        margin-left: 1rem !important;
        margin-right: 1rem !important;
    }
    

}