@import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@300;700&display=swap');

* {
  font-family: 'Readex Pro', sans-serif;
}

button#pay-btn {
  background-color:#5cb85c;
  color: #fff;
}

button#pay-btn:hover {
  background-color:#499e49;
  color: #fff;
}

#theLoader {
  z-index: -1;
}









@media (max-width: 768px) {

    
    a#donate-buttton {
        border-radius: 50px;
        font-size: 12px;
        padding: 10px 20px;
    }

    #theImg {
        height: 180px;
    }

    #theCard {
        height: 180px !important;
        margin-bottom: 1rem;
    } 
  
}
  
